import { Button, Form, Input, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useCustomer } from '../../hooks/use-customer';
import CheckCircle from '../../icons/check-circle';
import CloseCircle from '../../icons/close-circle';
import { Customer } from '../../types/Customer';
import AccountLayout, {
  BASE_ACCOUNT_PATH,
  PROFILE_PATH,
} from '../account/AccountLayout';
import Breadcrumb from '../Breadcrumb';
import MainLayout from '../layout/MainLayout';
import Section from '../Section';
import notification from '../utility/notification';

const { Text } = Typography;

interface formValue {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

const AccountPage = () => {
  const [loading, setLoading] = useState(false);
  const {
    customer: rawCustomer,
    actions: { updateCustomerDetails },
  } = useCustomer();
  const [form] = Form.useForm<formValue>();

  useEffect(() => {
    form.resetFields();
  }, [rawCustomer]);
  const customer = rawCustomer as Customer | undefined;

  const onSubmit = async (formValues: formValue) => {
    try {
      setLoading(true);
      await updateCustomerDetails(formValues);
      notification({
        key: 'update-account-success',
        type: 'success',
        message: 'แก้ไขข้อมูลส่วนตัวของคุณสำเร็จ',
      });
    } catch (_) {
      notification({
        key: 'update-account-error',
        type: 'error',
        message: 'พบข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayout>
      <Breadcrumb
        items={[
          {
            title: 'บัญชีของฉัน',
            link: BASE_ACCOUNT_PATH,
          },
          {
            title: 'ข้อมูลส่วนตัว',
            link: PROFILE_PATH,
          },
        ]}
        title="ข้อมูลส่วนตัว"
      />
      <Section className="py-6">
        <AccountLayout>
          <div className="p-6 bg-neutral-1 rounded-[6px] shadow-small border-light-divider border">
            <Form
              form={form}
              className="max-w-[340px]"
              onFinish={onSubmit}
              layout="vertical"
              autoComplete="off"
              disabled={loading}
            >
              <Form.Item
                name="first_name"
                label="ชื่อ"
                initialValue={customer?.first_name}
                required={false}
                rules={[{ required: true, message: 'กรุณากรอกชื่อ' }]}
              >
                <Input />
              </Form.Item>
              <div className="w-[16px]"></div>
              <Form.Item
                name="last_name"
                label="นามสกุล"
                initialValue={customer?.last_name}
                required={false}
                rules={[{ required: true, message: 'กรุณากรอกนามสกุล' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label="อีเมล"
                initialValue={customer?.email}
                required={false}
                rules={[
                  { required: true, message: 'กรุณากรอกอีเมล' },
                  { type: 'email', message: 'กรุณากรอกอีเมลให้ถูกต้อง' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="phone"
                initialValue={customer?.phone}
                label="เบอร์ติดต่อ"
                required={false}
                rules={[{ required: true, message: 'กรุณากรอกเบอร์ติดต่อ' }]}
              >
                <Input />
              </Form.Item>
              <Button type="primary" loading={loading} htmlType="submit">
                Save
              </Button>
            </Form>
          </div>
        </AccountLayout>
      </Section>
    </MainLayout>
  );
};

export default AccountPage;
